<!-- =========================================================================================
  File Name: FullPageBanner.vue
  Description: Full page with banner layout
========================================================================================== -->

<template>
  <div id="main-wrapper">
    <header class="main-header">
      <!-- Main Box -->
      <div class="main-box">
        <div class="auto-container">
          <div class="outer-container clearfix">
            <!--Logo Box-->
            <div class="logo-box">
              <div class="logo hide-on-mobile">
                <a :href="homeLink">
                  <picture>
                    <img
                      src="https://mylastwill.s3.amazonaws.com/static/img/home/logo-2.png"
                      alt="MyLastWill logo"
                    />
                  </picture>
                </a>
              </div>
              <div class="logo show-on-mobile">
                <a :href="homeLink">
                  <picture>
                    <img
                      src="https://mylastwill.s3.amazonaws.com/static/img/home/logo-2-white.png"
                      alt="MyLastWill logo"
                    />
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="q-progress">
      <div
        class="determinate"
        id="percentage"
        :style="{ width: percentage }"
      ></div>
    </div>

    <div
      v-if="coupon"
      aria-expanded="false"
      data-toggle="collapse"
      href="#collapseLaptop"
      class="collapseLaptop-wrapper"
    >
      <a
        @click.stop="show.continueOnPc = !show.continueOnPc"
        class="collapseLaptop"
      >
        Continue on PC / Laptop?
      </a>
      <div
        :class="{ show: show.continueOnPc }"
        class="collapse"
        id="collapseLaptop"
        style=""
      >
        <a
          @click.stop="show.continueOnPc = !show.continueOnPc"
          class="close-notice"
          ><i class="fa fa-times"></i
        ></a>
        <div class="collapseLaptopMsg">
          To make your Will on a laptop or other device visit
          <span @click.stop="copyLink" id="link"
            >{{ link }} <sup> <i class="fa fa-clone"></i></sup
          ></span>
          or visit <span>mylastwill.co.uk</span> and create your will and apply
          the discount code
          <span @click.stop="copyCode"
            >{{ coupon.code }} <sup> <i class="fa fa-clone"></i></sup
          ></span>

          <input
            id="linkInput"
            style="position: absolute; left: -1000px; top: -1000px"
            :value="link"
          />
          <input
            id="codeInput"
            style="position: absolute; left: -1000px; top: -1000px"
            :value="coupon.code"
          />
        </div>
        <picture>
          <source
            type="image/webp"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/continue-mobile.webp
            "
          />
          <source
            type="image/png"
            srcset="
              https://mylastwill.s3.amazonaws.com/static/img/home/resource/continue-mobile.png
            "
          />
          <img
            src="https://mylastwill.s3.amazonaws.com/static/img/home/resource/continue-mobile.png"
            alt="Continue on pc/laptop"
          />
        </picture>
      </div>
    </div>

    <div class="page-wrapper">
      <router-view @progress="percentage = $event"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullPageBanner',
  computed: {
    homeLink() {
      return process.env.NODE_ENV === 'production'
        ? 'https://www.mylastwill.co.uk/'
        : 'http://127.0.0.1:8000/'
    },
    coupon() {
      if (
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon
      )
        return this.$store.getters.checkoutBasket.coupon
      return null
    },
    link() {
      return process.env.NODE_ENV === 'production'
        ? 'wills.mylastwill.co.uk/coupon/' + this.coupon.code
        : 'http:127.0.0.1:7000/coupon/' + this.coupon.code
    }
  },
  data() {
    return {
      show: {
        continueOnPc: false
      },
      percentage: '0%'
    }
  },
  methods: {
    copyLink() {
      var link = document.getElementById('linkInput')
      link.select()
      link.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$notification.success('Link copied to clipboard')
    },
    copyCode() {
      var link = document.getElementById('codeInput')
      link.select()
      link.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$notification.success('Code copied to clipboard')
    }
  }
}
</script>

<style scoped>
.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}
#main-wrapper .page-wrapper > :first-child {
  padding-top: 80px;
  min-height: 100vh;
}
ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #e56781;
}

a:hover,
a:focus,
a:visited,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

/***

    ====================================================================
        Preloader
    ====================================================================

    ***/

.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
}

.lds-ripple {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.lds-ripple .lds-pos {
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  border: 4px solid #4f9b7e;
  border-radius: 30px;
  height: 40px;
  width: 40px;
  position: absolute;
  display: inline-block;
  margin-top: 20px;
  text-align: center;
}

.lds-ripple .lds-pos:nth-child(2) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes pulsate {
  0% {
    border: 3px solid #3e4268;
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }

  15% {
    border: 3px solid #3e4268;
    opacity: 1;
  }

  50% {
    border: 3px solid #3e4268;
    opacity: 0.8;
  }

  100% {
    border: 3px solid #3e4268;
    -webkit-transform: scale(2, 2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    border: 3px solid #3e4268;
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }

  15% {
    border: 3px solid #3e4268;
    opacity: 1;
  }

  50% {
    border: 3px solid #3e4268;
    opacity: 0.8;
  }

  100% {
    border: 3px solid #3e4268;
    -webkit-transform: scale(2, 2);
    opacity: 0;
  }
}

/***

    ====================================================================
        Scroll To Top style
    ====================================================================

    ***/

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 40px;
  height: 60px;
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 60px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  background: #2f2c2c;
  display: none;
  overflow: hidden;
  border-radius: 20px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  color: #ffffff;
  background: #e56781;
}

.scroll-to-top:hover span {
  -webkit-animation: bounce 1s linear;
  animation: bounce 1s linear;
}

/***

    ====================================================================
        Main Header style
    ====================================================================

    ***/

.main-header {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 99;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.main-header .auto-container {
  position: relative;
  padding: 0 0 0 15px;
}

.main-header .main-box {
  position: relative;
  padding: 0px 0px;
  left: 0px;
  top: 0;
  width: 100%;
  -webkit-transition: background 500ms ease;
  -moz-transition: background 500ms ease;
  -ms-transition: background 500ms ease;
  -o-transition: background 500ms ease;
  transition: background 500ms ease;
}

.main-header.fixed-header .main-box {
  position: fixed;
  top: 0;
  padding: 0px 0px;
  z-index: 999;
  opacity: 1;
  background-color: #ffffff;
  border-bottom: 1px solid #f4f5f7;
}

.main-header .main-box .outer-container {
  position: relative;
}

.main-header .main-box .logo-box {
  position: relative;
  float: left;
  left: 0px;
  z-index: 10;
  padding: 10px 0px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.fixed-header .main-box .logo-box {
  padding: 17px 0px 10px;
}

.main-header .main-box .logo-box .logo img {
  display: inline-block;
  height: 80px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.fixed-header .main-box .logo-box .logo img {
  height: 50px;
}

.main-header .nav-toggler button {
  position: relative;
  display: block;
  color: #444444;
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  font-weight: normal;
  background: none;
}

.main-header .nav-outer {
  position: relative;
  float: right;
  padding-top: 5px;
}

.main-header.fixed-header .nav-outer {
  padding-top: 0px;
}

.main-menu {
  position: relative;
  float: left;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: relative;
  margin: 0px;
}

.main-menu .navigation > li {
  position: relative;
  display: inline-block;
  padding: 32.5px 18px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li:last-child {
  margin-right: 0px;
}

.main-menu .navigation > li > a {
  position: relative;
  display: block;
  font-size: 15px;
  color: #2f2c2c;
  font-weight: 500;
  line-height: 1em;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 0px;
  opacity: 1;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .navigation > li.dropdown > a:before {
  font-family: 'FontAwesome';
  content: '\f107';
  position: absolute;
  right: 3px;
  font-size: 14px;
  margin-top: 2px;
}

.main-menu .navigation > li.dropdown a {
  padding-right: 18px;
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a {
  color: #e56781;
  opacity: 1;
}

.main-menu .navigation > li > a:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  background: #e56881;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
}

.main-menu .navigation > li:hover > a:after,
.main-menu .navigation > li.current > a:after {
  position: absolute;
  width: 100%;
}

.main-menu .navigation > li > ul {
  position: absolute;
  right: -50px;
  top: 100%;
  width: 320px;
  padding: 20px 0;
  z-index: 100;
  background: #fff;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 5px;
  -webkit-box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.16);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.16);
}

.main-menu .navigation > li > ul:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 10px;
  content: '';
}

.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
}

.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > a {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 40px 10px 40px;
  position: relative;
  color: #6a6a6a;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  display: block;
}

.main-menu .navigation > li > ul > li > a:hover {
  color: #2f2c2c;
}

.main-menu .navigation > li > ul > li > a:before {
  content: '';
  display: block;
  height: 100%;
  width: 3px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #e56781;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.main-menu .navigation > li > ul > li > a:hover:before {
  opacity: 1;
}

.main-menu .navigation > li > ul > li.dropdown > a:after {
  position: absolute;
  right: 15px;
  top: 11px;
  width: 10px;
  height: 20px;
  display: block;
  line-height: 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  z-index: 5;
  content: '\f105';
  font-family: 'FontAwesome';
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  left: 100%;
  top: -2px;
  width: 250px;
  padding: 10px 10px;
  z-index: 100;
  display: none;
  background: #2f2c2c;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -moz-transform-origin: left;
  transform-origin: left;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation > li > ul > li > ul:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 10px;
  outline: 1px solid #555555;
  outline-offset: -10px;
  content: '';
}

.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  width: 100%;
}

.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 20px;
  line-height: 24px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  text-align: left;
  text-transform: capitalize;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  border-left: 3px solid transparent;
}

.main-menu .navigation > li > ul > li > ul > li > a:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border-left: 3px solid #e56781;
}

.main-menu .navigation > li.dropdown:hover > ul {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -moz-transform: scaleY(1);
  transform: scaleY(1);
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.main-menu .navigation li > ul > li.dropdown:hover > ul {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1);
  visibility: visible;
  opacity: 1;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.main-header .info-options {
  position: relative;
  float: right;
  margin-left: 35px;
  padding: 25px 0px;
  line-height: 30px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.search-box-outer {
  position: relative;
  float: left;
}

.main-header .search-box-btn {
  position: relative;
  display: block;
  width: 100%;
  top: 1px;
  font-size: 15px;
  line-height: 20px;
  color: #2f2c2c;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  background: none;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header .search-box-btn:hover {
  color: #e56781;
}

.main-header .search-box-outer .dropdown-menu {
  top: 45px;
  border-top: 3px solid #e56781;
  padding: 0px;
  width: 280px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
}

.main-header.fixed-header .search-box-outer .dropdown-menu {
  top: 48px;
}

.main-header .search-box-outer .dropdown-menu > li {
  padding: 0px;
  border: none;
  background: none;
}

.main-header .search-panel .form-container {
  padding: 25px 20px;
}

.main-header .search-panel .form-group {
  position: relative;
  margin: 0px;
}

.main-header .search-panel input[type='text'],
.main-header .search-panel input[type='search'],
.main-header .search-panel input[type='password'],
.main-header .search-panel select {
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 7px 40px 7px 15px;
  height: 40px;
  color: #000000;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header .search-panel input:focus,
.main-header .search-panel select:focus {
  border-color: #e56781;
}

.main-header .search-panel .search-btn {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #262626;
  font-size: 14px;
  background: none;
  cursor: pointer;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header .search-panel .search-btn:hover {
  color: #d1e8ee;
}

/***

    ====================================================================
        Mobile Modificatios
    ====================================================================

    ***/

@media only screen and (max-width: 768px) {
  #main-wrapper .page-wrapper > :first-child {
    padding-top: 0;
  }
  .main-header.fixed-header .main-box,
  .main-header {
    background-color: #345a7a !important;
    position: relative;
  }

  .main-header .main-box .logo-box .logo img {
    height: 40px;
  }

  .main-header .main-box .logo-box {
    padding: 10px 0px 3px !important;
  }

  .main-header .main-box .logo-box .logo.hide-on-mobile {
    display: none;
  }

  .main-menu .navbar-header {
    margin-top: 0px !important;
    padding: 0px !important;
  }

  .main-menu .navbar-header .navbar-toggle {
    background-color: #517593;
    padding: 15px 0 !important;
    position: absolute !important;
    right: 0;
    width: 25% !important;
    bottom: 0;
  }

  .main-menu .navbar-header .navbar-toggle .icon-bar {
    background: #ffffff !important;
  }
}

@media only screen and (min-width: 769px) {
  .main-header .main-box .logo-box .logo.show-on-mobile {
    display: none;
  }
}

.q-progress {
  position: fixed;
  top: 0;
  height: 10px;
  display: block;
  width: 100%;
  background-color: #faf9fe;
  background-clip: padding-box;
  overflow: hidden;
  z-index: 1;
}
.q-progress .determinate {
  position: absolute;
  background-color: inherit;
  top: 0;
  bottom: 0;
  background-color: #f1dd6b;
  -webkit-transition: width 0.3s linear;
  -o-transition: width 0.3s linear;
  transition: width 0.3s linear;
}
@media screen and (max-width: 768px) {
  .q-progress {
    position: relative;
    background-color: #527693;
    height: 6px;
  }
}
</style>
